<template>
  <div>
    <div>
      <a-form :form="form" :wrapperCol="{xxl:17,xl:15}" :labelCol="{xxl:7,xl:9}" @submit="handleSubmit">
        <a-row gutter="20">
          <a-col
            :xxl="6"
            v-if="role==0">
            <a-form-item
              label="从属客户"
              :wrapperCol="{xxl:20,xl:15}"
              :labelCol="{xxl:4,xl:9}">
              <a-select v-decorator="['unit', { rules: [{ required: false}] }]">
                <a-select-option v-for="unit in units" :value="unit.id" :key="unit.id">{{ unit.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col
            :xxl="4">
            <a-form-item
              label="设备状态">
              <a-select v-decorator="['tag', { rules: [{ required: false}] }]">
                <a-select-option value="all">全部</a-select-option>
                <a-select-option value="used">已使用</a-select-option>
                <a-select-option value="unused">未使用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col
            :xxl="8">
            <a-form-item>
              <a-button type="primary" icon="search" html-type="submit">搜索</a-button>
              <a-button class="margin-left-16" icon="export" @click="exportData">导出数据</a-button>
            </a-form-item>

          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="ant-card">
      <s-table
        ref="table"
        size="small"
        :data="loadData"
        :columns="columns">

        <span slot="action" slot-scope="text, record">
          <a @click="showUserDetail(record.member_id)">明细</a>
        </span>
      </s-table>
    </div>

  </div>
</template>

<script>

import { STable } from '@/components'
import { loadAllUnitInfos, loadDeviceList } from '@/api/pc'
import UserDetailInfo from '@/views/user/UserDetailInfo'
import Vue from 'vue'
export default {
  name: 'Device',
  components: {
    STable
  },
  data () {
    return {
      role: -1,
      form: this.$form.createForm(this),
      columns: [{
        title: '设备MAC',
        dataIndex: 'device_mac',
        width: 160,
        align: 'center'
      },
        {
          title: '从属客户',
          dataIndex: 'customer_name',
          align: 'center'
        },
        {
          title: '订单编号',
          dataIndex: 'order_sn',
          align: 'center'
        },
        {
          title: '末次使用时间',
          dataIndex: 'last_measure',
          align: 'center'
        },
        {
          title: '认证时间',
          dataIndex: 'create_time',
          align: 'center'
        }],
      queryParams: {},
      loadData: parameter => {
        return loadDeviceList(Object.assign(this.queryParams, parameter)).then(res => {
          return res.result
        })
      },
      units: []
    }
  },
  created () {
    this.role = Vue.ls.get('role')
    loadAllUnitInfos().then(res => {
       this.units = res.data
    })
  },
  methods: {
    showUserDetail (member) {
      this.showModal(UserDetailInfo, '用户明细', 900, 600, { member: member })
    },
    exportData () {

    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.queryParams = values
          this.$refs.table.refresh()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
