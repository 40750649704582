<template>
  <div style="width: 100%;">
    <a-tabs>
      <a-tab-pane key="1" tab="用户信息">
        <div style="padding: 0 8px;">
          <a-row>
            <a-col :span="12">
              <div class="user-info-item">
                <div class="user-label">姓名</div>
                <div class="user-content">{{ userInfo.real_name||"未设置" }}</div>
                <a class="user-action" @click="editUserName">修改</a>
              </div>
            </a-col>
            <a-col :span="12">
              <div class="user-info-item">
                <div class="user-label">昵称</div>
                <div class="user-content">{{ userInfo.nickname }}</div>
              </div>
            </a-col>

          </a-row>
          <div style="margin-top: 2px;">
            <a-row>
              <a-col :span="12">
                <div class="user-info-item">
                  <div class="user-label">性别</div>
                  <div class="user-content">{{ userInfo.sex===1?'男':'女' }}</div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="user-info-item">
                  <div class="user-label">生日</div>
                  <div class="user-content">{{ userInfo.birthday }}</div>
                </div>
              </a-col>

            </a-row>
          </div>
          <div style="margin-top: 2px;">
            <a-row>
              <a-col :span="12">
                <div class="user-info-item">
                  <div class="user-label">年龄</div>
                  <div class="user-content">{{ userInfo.age }}</div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="user-info-item">
                  <div class="user-label">身高(cm)</div>
                  <div class="user-content">{{ userInfo.height }}</div>
                </div>
              </a-col>

            </a-row>
          </div>
          <div style="margin-top: 2px;">
            <a-row>
              <a-col :span="12">
                <div class="user-info-item">
                  <div class="user-label">目标体重(kg)</div>
                  <div class="user-content">{{ userInfo.goal_weight }}</div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="user-info-item">
                  <div class="user-label">手机号</div>
                  <div class="user-content">{{ userInfo.phone||'未设置' }}</div>
                </div>
              </a-col>
            </a-row>
          </div>
          <div style="margin-top: 2px;">
            <a-row>
              <a-col :span="24">
                <div class="user-info-item">
                  <div class="user-label">营养师</div>
                  <div class="user-content">{{ (userInfo.waiter?(userInfo.waiter.name+(serInfo.waiter.phone?('('+serInfo.waiter.phone+')'):'')):"") }}</div>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="称重数据" forceRender>
        <div>
          <div style="padding: 8px;">
            <div ref="hisChart" style="height: 300px;"></div>
          </div>
          <div style="padding: 0 16px;">
            <a-table :dataSource="records" :columns="columns" :scroll="{x:1600}" size="small"></a-table>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { loadUserInfo, loadUserWeightHistory } from '@/api/pc'
import EditUserName from '@/views/user/EditUserName'
var echarts = require('echarts')
export default {
  name: 'UserDetailInfo',
  props: ['member'],
  data () {
    return {
      userInfo: {},
      records: [],
      columns: [
        {
          title: '体重(kg)',
          dataIndex: 'weight',
          align: 'center',
          width: 80,
          fixed: 'left'
        },
        {
          title: '年龄',
          dataIndex: 'age',
          align: 'center',
          width: 80
        },
        {
          title: '身高(cm)',
          dataIndex: 'height',
          align: 'center'
        },
        {
          title: 'BMI',
          dataIndex: 'bmi',
          align: 'center'
        },
        {
          title: '体脂率(%)',
          dataIndex: 'bfr',
          align: 'center'
        },
        {
          title: '肌肉率(%)',
          dataIndex: 'rom',
          align: 'center'
        },
        {
          title: '蛋白率(%)',
          dataIndex: 'pp',
          align: 'center'
        },
        {
          title: '基础代谢率(kcal)',
          dataIndex: 'bmr',
          align: 'center'
        },
        {
          title: '内脏脂肪指数',
          dataIndex: 'uvi',
          align: 'center'
        },
        {
          title: '皮下脂肪率(%)',
          dataIndex: 'sfr',
          align: 'center'
        },
        {
          title: '骨骼肌率(%)',
          dataIndex: 'rosm',
          align: 'center'
        },
        {
          title: '称重时间',
          dataIndex: 'create_time',
          align: 'center',
          width: 180,
          fixed: 'right'
        }]
    }
  },
  created () {
    this.$nextTick(function () {
      this.loadMyUserInfo()
      this.loadMyUserWeightHistory()
    })
  },
  methods: {
    editUserName () {
       this.showModal(EditUserName, '修改用户真实姓名', 240, 150, { member: this.member })
    },
    setUserName (name) {
      this.userInfo = Object.assign(this.userInfo, { real_name: name })
    },
    loadMyUserInfo () {
      loadUserInfo({ member_id: this.member }).then(res => {
         if (res.code === 0) {
           this.userInfo = res.data
         }
      })
    },
    loadMyUserWeightHistory () {
      loadUserWeightHistory({ member_id: this.member }).then(res => {
        if (res.code === 0) {
          this.records = res.data
          var chartData = { x: [], y: [], y2: [] }
          for (var item of this.records) {
            var day = item.create_time.slice(0, 10)
            if (!chartData.x.includes(day)) {
              chartData.x.push(day)
              chartData.y.push(item.weight)
              chartData.y2.push(item.bfr)
            }
          }
          chartData.x = chartData.x.reverse()
          chartData.y = chartData.y.reverse()
          chartData.y2 = chartData.y2.reverse()
          var chart = echarts.init(this.$refs.hisChart)
          var option = {
            tooltip: {
              trigger: 'axis'
            },
            legend: {},
            grid: {
              left: '3%',
              right: '5%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: chartData.x
            },
            yAxis: [{
              name: '体重(kg)',
              type: 'value',
              minInterval: 1
            },
              {
                name: '体脂率(%)',
                type: 'value',
                minInterval: 1
              }],
            series: [
              {
                name: '体重',
                type: 'line',
                data: chartData.y,
                yAxisIndex: 0,
                smooth: true
              },
              {
                name: '体脂率',
                type: 'line',
                data: chartData.y2,
                yAxisIndex: 1,
                smooth: true
              }
            ]
          }
          chart.setOption(option)
        }
      })
    }
  }
}
</script>

<style scoped>
  .user-info-item{
    display: flex;
    border: 1px solid #4C4C4C;
    line-height: 40px;
  }

  .user-content{
    padding: 0 12px;
    color:#666;
    flex:1;
  }

  .user-label{
    font-weight: bold;
    background: #4C4C4C;
    color: white;
    width: 100px;
    text-align: center;
  }

  .user-action{
    margin-right: 8px;
  }
</style>
