<template>
  <div style="width: 100%;padding: 8px;">
    <div>
      <a-input placeholder="请输入客户姓名" v-model="name"></a-input>
    </div>
    <div class="margin-top-20">
      <div class="float-right">
        <a-button @click="closeWin">取消</a-button>
        <a-button class="margin-left-16" type="primary" @click="saveUserName">确定</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { saveUserName } from '@/api/pc'

export default {
  name: 'EditUserName',
  data () {
    return {
      name: ''
    }
  },
  props: ['layerid', 'member'],
  methods: {
    closeWin () {
      this.$layer.close(this.layerid)
    },
    saveUserName () {
       if (this.name.length === 0) {
          this.$message.error('用户姓名不能为空')
          return
       }
      saveUserName({ member_id: this.member, name: this.name }).then(res => {
          if (res.code === 0) {
             this.$parent.setUserName(this.name)
            this.closeWin()
          }
      })
    }
  }
}
</script>

<style scoped>

</style>
